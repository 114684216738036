.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  background: linear-gradient(90deg, #783737 0%, #1a2c4e 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.2vmin);
  color: white;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.board-box {
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.276);
  background: linear-gradient(72deg, #00000080, transparent);
}

.board {
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 1em;
  padding: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.board-info {
  display: flex;
  gap: 16px;
  align-items: center;
}

.board-info > * {
  width: 100%;
}

.state-label,
.timeout {
  color: rgb(170, 174, 189);
  font-weight: bold;
}

.timeout {
  white-space: nowrap;
  width: 50px;
}

.missions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
}

.circle {
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 4px rgb(0 0 0 / 23%);
}

.mission {
  text-shadow: 3px 1px 3px #424242;
  background-color: rgb(88 91 110);
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mission-result {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.276));
}


.mission-number {
  font-size: 2em;
}


.votes {
  margin-left: 6em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

.vote {
  border-radius: 50%;
  background-color: rgb(88 91 110);
  box-shadow: inset 0px 0px 4px 4px rgb(0 0 0 / 23%);
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 14mm;
}

.current_vote {
  background-color: rgb(31 53 197);
}

.vote:last-child {
  background-color: rgba(142, 6, 6, 0.68);
}

.other-players {
  display: flex;
  gap: 8px;
  justify-content: space-around;
}

.avatar.participant .avatar-img {
  border: 8px solid #2090e1;
  box-sizing: border-box;
  border-width: 8px;
}

.avatar {
  position: relative;
  width: min-content;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #14918d;
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  margin-top: 24px;
  color: white;
}


.avatar-img {
  transform: translateY(-20%);
  height: 128px;
  width: 128px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.132);
  border-radius: 50%;
}

.own-player, .other-player {
  display: flex;
}

.vote-card {
  width: 128px;
  height: 60px;
  background-color: rgb(82, 120, 125);
  border: 2px solid rgb(39, 52, 39);
  transition: 0.2s ease-in-out;
}

.leaderPlaceholder {
  position: absolute;
  width: 28%;
  bottom: 0;
  left: 0;
  transform: translate(55%, -115%);
}

.vote-selection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: end;
  margin-bottom: 8px;
  transform: translateX(-90%);
}

.vote-selection.voting {
  transform: translateX(-50%);
}

.vote-card {
  background-color: rgb(37, 42, 47);
  box-shadow: 2px 2px 2px 2px black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  padding: 8px;
  align-self: end;
  transition: 0.2s ease-in-out;
}

.voting 
.vote-card:hover {
  transform: translateX(20%);
}

.vote-selected,
.vote-selected:hover {
  transform: translateX(45%);
}


.vote-card>img {
  height: 100%;
  margin-left: 16px;
}


.card-background {
  background-image:
    repeating-linear-gradient(45deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.3) 100px,
      transparent 0px,
      transparent 200px),
    repeating-linear-gradient(-45deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.3) 100px,
      transparent 0px,
      transparent 200px);
}

.spy {
  background-color: #d50000;
}

.resistance {
  background-color: #4dff2e;
}

.mission-form {
  width: min-content;
  font-size: 20px;
  padding: 24px;
}

.mission-form>form {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 16px;
  grid-column-gap: 16px;
  align-items: center;
  justify-items: left;
  text-align: right;
}

.mission-form label,
span {
  font-weight: bold;
}

.form-pair {
  display: contents;
}

.mission-configs {
  gap: 16px;
  display: flex;
}

.mission-config {
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: nowrap;
}


input[type="number"] {
  width: min-content;
  text-align: right;
}

input[type="text"] {
  width: 6em;
}

input[type=number],
input[type="text"] {
  font-size: 22px;
  border-radius: 8px;
  padding: 4px;
}

input[type=number],
input[type="text"] {
  background-color: #353f5c;
  box-shadow: inset 5px 2px 7px 0px #252d40;
  border-width: 0;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  width: 10mm;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

button {
  font-size: 22px;
  padding: 8px;
  border-width: 0;
  background: rgb(55 56 92);
  box-shadow: 2px 2px 3px 2px #1b2625;
  padding: 8px;
  border-width: 0;
  color: white;
  text-shadow: 2px 2px 6px black;
  border-radius: 8px;
  font-weight: bold;
}

button:hover {
  transform: translate(3px, 3px);
  box-shadow: 2px 2px 3px 0px #1b2625;
}

.buttons {
  grid-column: -2;
  justify-self: right;
  display: flex;
  gap: 16px;
}

.lobbyPlayers {
  display: flex;
  gap: 8px;
  width: 75vw;
  flex-wrap: wrap;
  justify-content: space-around;
}
